.sidebar_main{
  .sidebar_parent_div{
    box-sizing: border-box;
    overflow-x: hidden;
    width: 270px;
    background-color: rgb(255,255,255, 1);
    height: 100%;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    z-index: 20;
    position: fixed;
    padding-bottom: 8rem;
  }
  .open{
    left: 0;
  }
  .close{
    left: -100%;
  }
  .sidebar_options_div{
    display: flex;
    width: 100%;
    flex-direction: column;

    .single_option_div{
      display: flex;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, .02, 1);
      transition-duration: 500ms;

      //&:hover{
      //  background-color: rgb(241, 241, 241);
      //  border-radius: 0.3rem;
      //  cursor: pointer;
      //  color: rgb(8, 101, 182);
      //}
    }

    .single_option_anchor{
      text-decoration: none;
    }
    .option_icon{
      width: 30%;
      display: grid;
      place-items: center;
    }

    .option_title{
      width: 70%;
      display: grid;
      font-weight: 600;
      list-style-type: none;
    }
  }
}