@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
body {
  font-family: "Roboto", sans-serif;
  background-color: #f3f4f6;
}

.editor-base {
  @apply prose prose-headings:m-0 prose-p:m-0  focus:outline-none max-w-none border border-gray-700 p-2 rounded;
}
